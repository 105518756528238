/**
 * First we will load all of this projects's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vue from 'vue';
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import App from './App.vue'
import PButton from "./components/global/TheButton"
import router from "./router/router"

Vue.component("p-button", PButton);
Vue.config.devtools = true

Vue.mixin({
    data: () => ({
        permissions: {},
    }),
    methods: {
        hasPermission(permission) {
            let self = this;
            return axios.get("/api/permissions/" + permission)
            .then(response => {
                let perm = permission.split('-').join('');
                if(response.data === 1){
                    self.$set(self.permissions, perm, true);
                }else{
                    self.$set(self.permissions, perm, false);
                }
            });
        }
    }
});

export const workMethodsMixin = {
    methods: {
        indexLabels() {
            return axios.get("/api/projects/" + this.projectId + "/labels")
                .then(response => {
                    this.labels = response.data;
                })
        },
        fillMap() {
            this.hotkeys = [49,50,51,52,53,54,55,56,57];
            for(let i = 0; i < this.labels.length; i++){
                this.keyLabelMap[this.hotkeys[i]] = this.labels[i].id;
            }
        },
        loadFirstFewFiles() {
            axios.get("/api/projects/" + this.projectId + "/unlabeled-files/")
                .then(response => {
                    this.files = response.data;
                    if(this.files.length < 1) {
                        alert("This Project has no unlabeled Files!");
                    }
                })
                .finally(() => {
                    this.currentFile = this.files[0];
                })
        },
        addLabel(_event) {
            if(this.hotkeys.includes(_event.which)) {
                axios.put("/api/files/" + this.currentFile.id, {
                    label_id: this.keyLabelMap[_event.which]
                })
                    .then(response => {
                        console.log(response);
                        this.progressTracker++;
                        this.currentFile = this.files[this.progressTracker];
                        if(!this.currentFile) {
                            alert("All Files have been labeled!");
                        }
                    })
                    .catch(e=> {
                        console.log(e);
                    })
            }
        }
    }
}

const app = new Vue({
    router,
    el: '#app',
    components: {
        App,
    },
    render: h => h(App)
});
