<template>
    <div>
        <template v-if="tooltip">
            <div id="tooltip" class="p-b-tooltip" v-show="buttonhover">
                <slot name="tooltipText">
                </slot>
            </div>
        </template>
        <button @mouseleave="hideTooltip" @mouseover="showTooltip" type="button">
            {{text}}
        </button>
    </div>
</template>

<script>
    export default {
        props : {
            text: {
                type: String,
                default: "Click me",
            },
            tooltip: {
                type: Boolean,
                default: false,
                // validator: function (value) {
                //     return ["left", "right", "top", "bottom"].indexOf(value) !== -1
                // }
            },
            callback: {
                type: function () {

                }
            }
        },

        data: () => ({
            buttonhover: false,
        }),

        methods: {
            showTooltip(event) {
                this.buttonhover = true;
                let a = this.$refs["tooltip"];
            },
            hideTooltip() {
                this.buttonhover = false;
            },
        },
    }
</script>

<style scoped>
    .p-b-tooltip {
        background-color: darkolivegreen;
        position: fixed;
        left: 10%;
        top: 0;
    }
</style>
