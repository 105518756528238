<template>
    <section>
        <div class="main-navigation" :class="{'main-navigation--open': toggle}" @click="toggle= !toggle">
            <div class="main-navigation__item main-navigation__item--toggle">
                <span class="main-navigation__icon main-navigation__icon--toggle icon icon--bars icon--size-18"></span>
            </div>
            <router-link v-if="permissions.viewanyproject" class="main-navigation__item" v-on:click.native="home= false" :to="{name: 'ProjectsView'}" >
                <span class="main-navigation__icon icon icon--clipboard icon--size-18"></span>
                <span class="main-navigation__text">Projektübersicht</span>
            </router-link>
            <router-link v-if="permissions.viewanyuser" class="main-navigation__item" v-on:click.native="home= false" :to="{name: 'UsersView'}">
                <span class="main-navigation__icon icon icon--users icon--size-18"></span>
                <span class="main-navigation__text">Nutzerübersicht</span>
            </router-link>
            <router-link v-if="permissions.viewanycompany" class="main-navigation__item" v-on:click.native="home= false" :to="{name: 'CompaniesView'}">
                <span class="main-navigation__icon icon icon--building icon--size-18"></span>
                <span class="main-navigation__text">Unternehmen</span>
            </router-link>         
            <router-link v-if="permissions.exportprojects" class="main-navigation__item" v-on:click.native="home= false" :to="{name: 'ExportView'}">
                <span class="main-navigation__icon icon icon--file-export icon--size-18"></span>
                <span class="main-navigation__text">Export</span>
            </router-link>
            <span @click="logout" class="main-navigation__item main-navigation__item--logout">
                <span class="main-navigation__icon main-navigation__icon--logout icon icon--sign-out icon--size-18"></span>
                <span class="main-navigation__text main-navigation__icon--logout">Abmelden</span>
            </span>
        </div>
        <div class="top-bar">
            <div class="top-bar__item top-bar__item--right">
                <span @click="logout" class="top-bar__icon icon icon--sign-out icon--size-30"></span>
            </div>
            <div class="top-bar__item">
                <img @click="routeHome" class="top-bar__logo" src="/assets/img/PreAI-Logo-Full.png"/>
            </div>
        </div>

        <div v-if="home" class="home">
            <h1 class="home__heading home__heading--level-1">Willkommen bei PreAI</h1>
            <p class="home__info">Im Projekt "Vorverarbeitung Bild und Video für KI" wurde diese Anwendung entwickelt, welche Firmen hilft, Bildmaterial für das Training ihrer KIs zu klassifizieren. <br><br>
            Über die Navigation können Sie die Anwendung selbst entdecken. <br>Tipp: Um den gesamten Umfang der Anwendung zu testen sollten Sie sich unter dem Menüpunkt "Unternehmen" eine eigene Firma inklusive Nutzer anlegen. Die Zugangsdaten erhalten Sie direkt als Mail.
            </p>
            <h2 class="home__heading home__heading--level-2">Das Projektteam:</h2>
            <div class="overview">
                <div class="overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2">
                    <div class="card card--full-width">
                        <div class="profile profile--detail">
                            <img class="profile--detail__image profile--detail__image--big" src="assets/img/team-egzon-demaj.jpeg"/>
                            <span class="profile--detail__desc">Egzon Demaj<br>(MIB)<br><br>Vue-Entwicklung<br>Laravel-Entwickung</span>
                        </div>
                    </div>
                </div>
                <div class="overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2">
                    <div class="card card--full-width">
                        <div class="profile profile--detail">
                            <img class="profile--detail__image profile--detail__image--big" src="assets/img/team-fabian-puetz.jpeg"/>
                            <span class="profile--detail__desc">Fabian Pütz<br>(OMB)<br><br>Konzeption<br>Film</span>
                        </div>
                    </div>
                </div>
                <div class="overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2">
                    <div class="card card--full-width">
                        <div class="profile profile--detail">
                            <img class="profile--detail__image profile--detail__image--big" src="assets/img/team-joshua-keller.jpeg"/>
                            <span class="profile--detail__desc">Joshua Keller<br>(OMB)<br><br>Design<br>Testing</span>
                        </div>
                    </div>
                </div>
                <div class="overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2">
                   <div class="card card--full-width">
                        <div class="profile profile--detail">
                            <img class="profile--detail__image profile--detail__image--big" src="assets/img/team-nina-hecht.jpg"/>
                            <span class="profile--detail__desc">Nina Hecht<br>(OMB)<br><br>Laravel-Entwicklung<br>Frontend-Entwicklung</span>
                        </div>
                    </div>
                </div>
                <div class="overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2">
                    <div class="card card--full-width">
                        <div class="profile profile--detail">
                            <img class="profile--detail__image profile--detail__image--big" src="assets/img/team-simon-maier.jpeg"/>
                            <span class="profile--detail__desc">Simon Maier<br>(OMB)<br><br>Konzeption<br>Vue-Entwicklung</span>
                        </div>
                    </div>
                </div>
                <div class="overview__item column--12 column--xs-6 column--s-4 column--m-4 column--l-4 column--xl-2">
                   <div class="card card--full-width">
                        <div class="profile profile--detail">
                            <img class="profile--detail__image profile--detail__image--big" src="assets/img/team-simon-pirmann.jpg"/>
                            <span class="profile--detail__desc">Simon Pirmann<br>(MIB)<br><br>Projektleitung<br>Laravel-Entwicklung</span>
                        </div>
                    </div>
                </div>
            </div>
            <p class="home__info">Um mehr Informationen zu dieser Anwendung, dem Klassifizierungsprozess und dem Thema KI allgemein zu erhalten besuchen sie unsere Homepage.</p>
            <a href= "https://www.preai.de/" target="_blank"
                class="form__item btn-primary btn-primary--only-text">
                <span class="btn-primary__text btn-primary--only-text__text">Homepage</span>
            </a>
        </div>

        <router-view>
        </router-view>
    </section>
</template>

<script>
    import ProjectsContainer from "./views/projects/ProjectsView";

    const axios = require("axios").default;

    export default {
        components: {
            ProjectsContainer,
        },
        data: () => ({
            toggle: false,
            home: false,
        }),
        async created() {
            axios.interceptors.response.use(
                (response) => response, (error) => {
                    if(error.response.status === 403){
                        this.$router.push({ name: 'Forbidden' });
                } else if(error.response.status === 404){
                    this.$router.push({ name: 'notFound' });
                }
            });

            await this.hasPermission('view-any-company');
            await this.hasPermission('view-any-project');
            await this.hasPermission('view-any-user');
            await this.hasPermission('export-projects');

            if(this.$router.currentRoute.name === 'home'){
                this.home = true;
            }
        },
        methods: {
            logout() {
                axios.post("/logout")
                    .then((res) => {
                        window.location.href="/";
                    });
            },
            routeHome() {
                this.$router.push({name: "home"});
                this.home = true;
            }
        },
    }
</script>
